import { type ComponentType } from 'react';

import { Memory1, Memory2 } from './Layout1';
import { Memory3, Memory4 } from './Layout2';
import { type LayoutProps } from './types';

type MemoryTemplate = {
  id: string;
  component: ComponentType<LayoutProps>;
};

export const MEMORY_TEMPLATES: MemoryTemplate[] = [
  {
    id: 'MemoriesSouvenir1',
    component: Memory1,
  },
  {
    id: 'MemoriesSouvenir3',
    component: Memory3,
  },
  {
    id: 'MemoriesSouvenir2',
    component: Memory2,
  },
  {
    id: 'MemoriesSouvenir4',
    component: Memory4,
  },
];
