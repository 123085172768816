import axios from 'axios';
import useSWR from 'swr';

import {
  type DtoGroupPhoto,
  EnumsGroupPhotoStatus,
} from '@lp-lib/api-service-client/public';

import { apiService } from '../../services/api-service';

type UseSessionGroupPhoto = {
  groupPhoto: DtoGroupPhoto | null | undefined;
  isLoading: boolean;
  error: Error | undefined;
};

async function loadGroupPhoto(
  sessionId: string
): Promise<DtoGroupPhoto | null> {
  try {
    const resp = await apiService.session.getSessionGroupPhoto(sessionId);
    return resp.data;
  } catch (error) {
    if (axios.isAxiosError(error)) {
      if (error.response?.status === 404) {
        return null;
      }
    }
    throw error;
  }
}

function refreshInterval(data: DtoGroupPhoto | null | undefined) {
  if (data === null) {
    return 0;
  }

  return data?.status === EnumsGroupPhotoStatus.GroupPhotoStatusCompleted
    ? 0
    : 1200;
}

export function useSessionGroupPhoto(
  sessionId: string | null | undefined
): UseSessionGroupPhoto {
  const { data, error, isLoading } = useSWR(
    sessionId ? ['/sessions/group-photo', sessionId] : null,
    ([_, sessionId]) => loadGroupPhoto(sessionId),
    {
      shouldRetryOnError: false,
      refreshInterval,
    }
  );

  return {
    groupPhoto: data,
    isLoading,
    error,
  };
}
