import React, { type ComponentType } from 'react';

import { GamePackCover } from '../../../components/Game/Utilities';
import {
  JoyCapture,
  type JoyCaptureRenderer,
  type JoyCaptureStyles,
  UserJoyCapture,
} from '../../../components/JoyCapture';
import { getStaticAssetPath } from '../../../utils/assets';
import { ordinal } from '../../../utils/common';
import { type LayoutProps, type Player } from './types';

const maxNumJoyCaptures = 5;

type CommonJoyCaptureProps = {
  styles: JoyCaptureStyles;
  noAnimate?: boolean;
  renderer?: JoyCaptureRenderer;
};

function JoyCapturesLayout(props: {
  numTeamMembers: number;
  joyCaptureComponents: ComponentType<CommonJoyCaptureProps>[];
  noAnimate?: boolean;
  renderer?: JoyCaptureRenderer;
}): JSX.Element {
  const { numTeamMembers, joyCaptureComponents, noAnimate, renderer } = props;

  if (numTeamMembers === 0) {
    return <></>;
  } else if (numTeamMembers === 1) {
    const JoyCapture = joyCaptureComponents[0];
    return (
      <JoyCapture
        styles={{ size: 'w-full h-full', shape: 'rounded-none' }}
        noAnimate={noAnimate}
        renderer={renderer}
      />
    );
  } else {
    const toRender = joyCaptureComponents.slice(0, maxNumJoyCaptures);
    const numNotShown = numTeamMembers - toRender.length;
    const childStyle = {
      flexBasis: numTeamMembers < maxNumJoyCaptures ? '50%' : '30%',
      maxWidth: '50%',
      height: numTeamMembers === 2 ? '66%' : '50%',
    };
    const joyCaptureStyles: JoyCaptureStyles = {
      size: 'w-full h-full',
      shape: 'rounded-none',
      aspectFill: numTeamMembers === 2 ? 'h-full' : undefined,
    };
    return (
      <div className='w-full h-full flex items-center justify-center flex-wrap'>
        {toRender.map((JoyCapture, i) => {
          return (
            <div
              key={i}
              style={childStyle}
              className={`flex-1 overflow-hidden flex items-center justify-center`}
            >
              <JoyCapture
                styles={joyCaptureStyles}
                noAnimate={noAnimate}
                renderer={renderer}
              />
            </div>
          );
        })}

        {numNotShown > 0 && (
          <div
            style={childStyle}
            className={`
              flex-1 overflow-hidden
              flex flex-col items-center justify-center 
              text-center font-bold text-sms text-secondary
            `}
          >
            +{numNotShown}
            <br />
            more!
          </div>
        )}
      </div>
    );
  }
}

function Layout2(
  props: {
    bg: string;
    overlay?: string;
    header: React.ReactNode;
  } & LayoutProps
): JSX.Element {
  const joyCaptures = props.teamMembers.map(
    (tm) => (props: CommonJoyCaptureProps) =>
      <JoyCapture joyCapture={tm.joyCapture} {...props} />
  );

  return (
    <div
      className='relative bg-cover bg-no-repeat bg-center w-[405px] h-[405px] overflow-hidden'
      style={{ backgroundImage: `url('${props.bg}')` }}
    >
      {props.overlay && (
        <img
          src={props.overlay}
          className='absolute w-full h-full'
          alt='Overlay'
        />
      )}
      <div className='h-2/3 pt-[4%] px-[3.5%]'>
        <div className='w-full h-full rounded-sm overflow-hidden flex items-center justify-center bg-black bg-opacity-5'>
          <JoyCapturesLayout
            {...props}
            numTeamMembers={props.teamMembers.length}
            joyCaptureComponents={joyCaptures}
          />
        </div>
      </div>

      <div className='h-1/3'>
        <div className='h-1/3 w-4/5 mx-auto flex items-center justify-center'>
          {props.header}
        </div>
        <div className='h-1/3 w-2/3 mx-auto flex items-center justify-center text-center text-lg'>
          <div className='line-clamp-2'>
            {props.teamMembers.map((t) => t.name).join(', ')}
          </div>
        </div>
      </div>

      {props.gamePack && (
        <div className='absolute bottom-0 right-0 left-0'>
          <div className='absolute right-[3%] -bottom-1.5 w-[23%] transform rotate-[6.52deg]'>
            <GamePackCover pack={props.gamePack} outerClassName='shadow-xl' />
          </div>
        </div>
      )}
    </div>
  );
}

const bgMemory3 = getStaticAssetPath('images/bg-memory-3-v2.png');
export function Memory3(props: LayoutProps): JSX.Element {
  return (
    <Layout2
      {...props}
      bg={bgMemory3}
      header={
        <div
          className='text-2xl text-center font-bold truncate transform rotate-[-2deg]'
          style={{ fontFamily: 'Carter One' }}
        >
          Let’s Go {props.teamName}!
        </div>
      }
    />
  );
}

const bgMemory4 = getStaticAssetPath('images/bg-memory-4.png');
const overlayMemory4 = getStaticAssetPath('images/overlay-memory-4.png');
export function Memory4(props: LayoutProps): JSX.Element {
  return (
    <Layout2
      {...props}
      bg={bgMemory4}
      overlay={overlayMemory4}
      header={
        <div
          className='text-2xl text-center font-bold truncate transform rotate-[-2deg]'
          style={{ fontFamily: 'Single Day' }}
        >
          Let’s Go {props.teamName}!
        </div>
      }
    />
  );
}

const polaroidBg = getStaticAssetPath('images/bg-polaroid.png');
export function LeaderboardPolaroid(props: {
  rank: number | null;
  score: number | null;
  teamName: string;
  teamMembers: string;
  numTeamMembers: number;
  joyCaptureComponents: ComponentType<CommonJoyCaptureProps>[];
  renderer: JoyCaptureRenderer;
}): JSX.Element {
  const { rank, score } = props;
  const hasRankAndScore = rank !== null && score !== null;

  return (
    <div className='w-[232px] h-[240px] bg-white shadow-xl rounded-md relative'>
      <div className='absolute left-0 right-0 flex justify-center -top-4'>
        <div
          className={`bg-[#FFF0B9] px-2 flex items-center gap-2 shadow-sm ${
            !hasRankAndScore ? 'w-1/2 h-7' : ''
          }`}
        >
          {hasRankAndScore && (
            <>
              <div className='text-[#E63B3F] transform rotate-[-2deg] italic font-black text-xs'>
                {ordinal(rank)}
              </div>
              <div className='font-black text-lg tracking-[0.15px]'>
                {new Intl.NumberFormat().format(score)}
              </div>
            </>
          )}
        </div>
      </div>

      <div className='px-2 pt-4.5 pb-1'>
        <div
          className='w-full h-37 rounded-sm overflow-hidden flex items-center justify-center'
          style={{
            backgroundImage: `url('${polaroidBg}')`,
          }}
        >
          <JoyCapturesLayout {...props} />
        </div>
      </div>
      <div className='mt-1 px-2 truncate font-bold text-sms text-center'>
        {props.teamName}
      </div>
      <div className='mt-1 h-9 text-center text-2xs tracking-[0.15px] flex items-center justify-center px-3'>
        <div className='line-clamp-3'>{props.teamMembers}</div>
      </div>
    </div>
  );
}

export function ScoreboardPolaroid(props: {
  rank: number | null;
  score: number | null;
  teamName: string;
  teamMembers: Player[];
  renderer: JoyCaptureRenderer;
}): JSX.Element {
  const joyCaptures = props.teamMembers.map(
    (tm) => (props: CommonJoyCaptureProps) =>
      <JoyCapture joyCapture={tm.joyCapture} {...props} />
  );

  return (
    <LeaderboardPolaroid
      rank={props.rank}
      score={props.score}
      teamName={props.teamName}
      teamMembers={props.teamMembers
        .map((t) => t.name.trim())
        .filter((t) => t.length > 0)
        .join(', ')}
      numTeamMembers={props.teamMembers.length}
      joyCaptureComponents={joyCaptures}
      renderer={props.renderer}
    />
  );
}

export function PairingLeaderboardPolaroid(props: {
  rank: number;
  score: number;
  teamName: string;
  teamMembers: string;
  uids: string[];
  sessionId: string | null;
  renderer: JoyCaptureRenderer;
}): JSX.Element {
  // TODO(falcon): return the JoyCapture from the backend on the leaderboard.
  // the number of the teams is bounded, but this will be more efficient if the backend
  // returns the data rather than requerying for it here.
  const joyCaptures = props.uids.map(
    (uid) => (innerProps: CommonJoyCaptureProps) =>
      (
        <UserJoyCapture
          uid={uid}
          sessionId={props.sessionId ?? undefined}
          {...innerProps}
        />
      )
  );

  return (
    <LeaderboardPolaroid
      rank={props.rank}
      score={props.score}
      teamName={props.teamName}
      teamMembers={props.teamMembers}
      numTeamMembers={props.uids.length}
      joyCaptureComponents={joyCaptures}
      renderer={props.renderer}
    />
  );
}
