import React, { useMemo } from 'react';

import { JoyCapture } from '../../../components/JoyCapture';
import { getStaticAssetPath } from '../../../utils/assets';
import { type LayoutProps } from './types';

const maxNumJoyCaptures = 5;

const circleRingColors = [
  'ring-[#FBB707]',
  'ring-[#FF3975]',
  'ring-[#15DF96]',
  'ring-[#8C6FFF]',
  'ring-[#3988FF]',
];

type Position = {
  size: number;
  top?: number;
  right?: number;
  left?: number;
  bottom?: number;
};

const circle1LayoutConfig: Record<number, Position[]> = {
  2: [
    {
      size: 41,
      top: 34,
      right: 11,
    },
    {
      size: 35,
      top: 18,
      left: 9,
    },
  ],
  3: [
    {
      size: 35,
      bottom: 10,
      left: 20,
    },
    {
      size: 30,
      top: 17,
      left: 14,
    },
    {
      size: 33,
      top: 22,
      right: 13.5,
    },
  ],
  4: [
    {
      size: 34.6,
      bottom: 14,
      left: 8,
    },
    {
      size: 28,
      top: 17.5,
      left: 20.3,
    },
    {
      size: 31,
      top: 25,
      right: 11,
    },
    {
      size: 29,
      bottom: 7,
      right: 21,
    },
  ],
  5: [
    {
      size: 33,
      bottom: 33,
      left: 33,
    },
    {
      size: 28,
      top: 18,
      left: 4,
    },
    {
      size: 24.7,
      bottom: 21,
      left: 8,
    },
    {
      size: 25,
      bottom: 6,
      right: 22.4,
    },
    {
      size: 27,
      top: 23,
      right: 3.3,
    },
  ],
};

function JoyCapturesLayout({
  teamMembers,
  noAnimate,
  renderer,
}: LayoutProps): JSX.Element {
  const numTeamMembers = teamMembers.length;
  if (numTeamMembers === 0) {
    return <></>;
  } else if (numTeamMembers === 1) {
    return (
      <div className='w-full h-full flex items-center justify-center'>
        <JoyCapture
          joyCapture={teamMembers[0].joyCapture}
          styles={{ border: 'ring-4 ring-tertiary', size: 'w-[58%] h-[58%]' }}
          noAnimate={noAnimate}
          renderer={renderer}
        />
      </div>
    );
  } else {
    const toRender = teamMembers.slice(0, maxNumJoyCaptures);
    const numNotShown = numTeamMembers - toRender.length;
    const config = circle1LayoutConfig[toRender.length];
    return (
      <div className='w-full h-full relative'>
        {toRender.map((tm, i) => {
          const props = config[i];
          return (
            <div
              key={i}
              className='absolute'
              style={{
                width: `${props.size}%`,
                aspectRatio: '1 / 1',
                top: props.top ? `${props.top}%` : undefined,
                right: props.right ? `${props.right}%` : undefined,
                left: props.left ? `${props.left}%` : undefined,
                bottom: props.bottom ? `${props.bottom}%` : undefined,
              }}
            >
              <JoyCapture
                joyCapture={tm.joyCapture}
                styles={{
                  border: `ring-4 ${circleRingColors[i]}`,
                  size: 'w-full h-full',
                }}
                noAnimate={noAnimate}
                renderer={renderer}
              />
            </div>
          );
        })}
        {numNotShown > 0 && (
          <div
            className={`
              absolute right-[6%] bottom-[28.5%]
              flex flex-col items-center justify-center 
              text-center font-bold text-lg text-white
            `}
          >
            +{numNotShown}
            <br />
            more!
          </div>
        )}
      </div>
    );
  }
}

function Layout1(
  props: {
    bg: string;
    header: React.ReactNode;
  } & LayoutProps
): JSX.Element {
  return (
    <div
      className='relative bg-cover bg-no-repeat bg-center w-[405px] h-[405px] overflow-hidden'
      style={{ backgroundImage: `url('${props.bg}')` }}
    >
      <div className='h-[14%] flex-none'>
        <div
          className={`
            h-full w-full px-4
            flex items-end justify-center
          `}
        >
          {props.header}
        </div>
      </div>
      <div className='absolute inset-0'>
        <div className='w-full h-full'>
          <JoyCapturesLayout {...props} />
        </div>
      </div>
    </div>
  );
}

const bgMemory1 = getStaticAssetPath('images/bg-memory-1.png');
export function Memory1(props: LayoutProps): JSX.Element {
  return (
    <Layout1
      {...props}
      bg={bgMemory1}
      header={
        <div className='text-2xl text-center font-bold truncate bg-clip-text bg-memories-text text-transparent'>
          Great Job {props.teamName}!
        </div>
      }
    />
  );
}

const bgMemory2 = getStaticAssetPath('images/bg-memory-2.png');
export function Memory2(props: LayoutProps): JSX.Element {
  const style = useMemo(
    () => ({
      fontFamily: 'Bangers',
      textShadow:
        '-1px -1px 0 #000, 1px -1px 0 #000, -1px 1px 0 #000, 1px 1px 0 #000, 3px 2px 0px #FF3D9A',
    }),
    []
  );

  return (
    <Layout1
      {...props}
      bg={bgMemory2}
      header={
        <div
          className='flex-1 text-3xl text-center text-tertiary font-bold truncate'
          style={style}
        >
          Chilling with {props.teamName}
        </div>
      }
    />
  );
}
