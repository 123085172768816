import useSWRImmutable from 'swr/immutable';

import { useFeatureQueryParam } from '../../hooks/useFeatureQueryParam';
import { apiService } from '../../services/api-service';
import { type LeaderboardTeam, type SessionMemories } from '../../types';

type UseSessionMemories = {
  session: SessionMemories | undefined;
  pairingLeaderboard: LeaderboardTeam[] | undefined;
  isLoading: boolean;
  error: Error | undefined;
};

type Data = {
  session: SessionMemories;
  pairingLeaderboard?: LeaderboardTeam[];
};

async function loadSessionMemories(
  sessionId: string,
  useParticipantTeamId = false
): Promise<Data> {
  const resp = await apiService.session.getSessionMemories(
    sessionId,
    useParticipantTeamId
  );
  const session = resp.data.session;

  if (session.pairingId && session.pairingRoundId) {
    const pairingLeaderboard = await apiService.pairing
      .getOrgLeaderboardTeams(session.pairingRoundId)
      .next();
    return { session, pairingLeaderboard };
  }

  return { session };
}

export function useSessionMemories(
  sessionId: string | null | undefined
): UseSessionMemories {
  const useParticipantTeamId = useFeatureQueryParam(
    'memories-use-participant-team-id'
  );

  const { data, error, isValidating } = useSWRImmutable(
    sessionId ? ['/sessions/memories', sessionId] : null,
    ([_, sessionId]) => loadSessionMemories(sessionId, useParticipantTeamId),
    {
      shouldRetryOnError: false,
    }
  );

  return {
    session: data?.session,
    pairingLeaderboard: data?.pairingLeaderboard,
    isLoading: isValidating,
    error,
  };
}
