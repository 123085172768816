import { Loading } from '../../../components/Loading';
import { getStaticAssetPath } from '../../../utils/assets';
import { useSessionGroupPhoto } from '../useSessionGroupPhoto';

const bgGroupPhoto = getStaticAssetPath('images/bg-group-photo.png');

export function GroupPhoto(props: {
  sessionId: string;
  background?: string | null;
}): JSX.Element | null {
  const { sessionId, background } = props;
  const { groupPhoto, error } = useSessionGroupPhoto(sessionId);
  if (groupPhoto === null) return null;

  if (groupPhoto?.url) {
    return (
      <img src={groupPhoto.url} alt='Group Joy Capture' className='h-[405px]' />
    );
  }

  return (
    <div
      className='w-[720px] h-[405px] bg-center bg-no-repeat bg-cover flex flex-col items-center justify-center'
      style={{
        backgroundImage: `url(${background || bgGroupPhoto})`,
      }}
    >
      {error ? (
        <div className='pt-2 text-white text-sm text-center'>
          Hmm...we’re having some trouble generating your group photo. Try
          refreshing.
        </div>
      ) : (
        <>
          <Loading text='' />
          <div className='pt-2 text-white text-sm text-center'>
            We’re generating your group photo. It will appear here when ready.
            <br />
            Click the arrows to see your team photos.
          </div>
        </>
      )}
    </div>
  );
}
